/**
 * Sets defaults for all theme options.
 */

const DEFAULT_OPTIONS = {
  //Site identity
  wordPressUrl: process.env.GATSBY_WORDPRESS_API_URL,
  siteUrl: "https://tactic.au",
  wordPressBaseUrl: "https://tacticproperty.com.au/",
  siteTitle: "TACTIC",
  siteDescription:
    "We are an end to end solution for creating workspaces that engage",
  contact: {
    email: "mel@tactic.au",
    address: {
      street: "Level 33, Riparian Plaza, 71 Eagle Street",
      city: "Brisbane",
      state: "QLD",
      zip: "4000",
    },
    address2: {
      street: "The Porter, 1 O'Connell Street",
      city: "Sydney CBD",
      state: "NSW",
      zip: "2000",
    },
    phone: "0481738474",
  },
  googleMapsLink: "https://goo.gl/maps/TAqB4eGm8Q3SCJrk6",
  googleMapsLink2: "https://maps.app.goo.gl/fEMUJtaJrxP5YvfF6",
  socialFollowLinks: [
    { name: "dribbble", url: null },
    { name: "facebook", url: "https://www.facebook.com/tacticproperty/" },
    { name: "github", url: null },
    { name: "instagram", url: "https://www.instagram.com/tacticproperty" },
    {
      name: "linkedin",
      url: "https://au.linkedin.com/company/tactic-property",
    },
    { name: "medium", url: null },
    { name: "pinterest", url: "https://www.pinterest.com.au/TACTICAU/" },
    { name: "reddit", url: null },
    { name: "snapchat", url: null },
    { name: "soundcloud", url: null },
    { name: "tiktok", url: "https://www.tiktok.com/@melpikos" },
    { name: "twitter", url: "https://mobile.twitter.com/mel_pikos" },
    { name: "vimeo", url: null },
    {
      name: "youtube",
      url: "https://www.youtube.com/channel/UCLkBXZd2bIHrnb7KxI1jejw",
    },
  ],
  cssVarPrefix: "tactic",
  createColorPallete: true,

  //Theme options
  gatsbySourceWordPressOptions: { presets: [] },
  pathPrefix: "",
  paginationPrefix: "page",
  addWordPressComments: false,
  addWordPressSearch: false,
  gaTrackingId: null,
  gaOptions: {},
  youTubeEmbed: true,
  youTubeChannelId: "UCLkBXZd2bIHrnb7KxI1jejw",
  googleTagManagerId: null,
  googleTagManagerOptions: {},
  archiveSidebarPosition: "right",
  pageCreateDebugOutput: false,

  useGravityForms: false,
  useNetlifyForms: false,
  developLimit: 20,
  addColorModes: true,
  webfontsOptions: {},
  useTypekitFonts: true,
  widgetAreas: {
    slideMenuWidgets: [
      /*"Categories", "RecentPosts", "Tags", "SocialFollow"*/
    ],
    sidebarWidgets: ["Categories", "RecentPosts"],
  },

  // Build commands
  createPages: true,
  createPosts: true,
  createCategories: true,
  createTags: false,
  createUsers: false,
  createMemorandums: true,
  createProperties: true,
  createSuites: true,
  useWpRedirects: true,
  withApollo: false,

  addSiteMap: true,
  submitSitemapAuto: true,
  siteMapOptions: {
    query: `
          {
            allWpPost {
              edges {
                node {
                  dateGmt
                  modifiedGmt
                  status
                  id
                  slug
                  uri
                  seo {
                    metaRobotsNoindex
                  }
                }
              }
            }
            allWpProperty {
              edges {
                node {
                  dateGmt
                  modifiedGmt
                  status
                  id
                  slug
                  uri
                  seo {
                    metaRobotsNoindex
                  }
                }
              }
            }      
            allWpPage {
              edges {
                node {
                  dateGmt
                  modifiedGmt
                  status
                  isFrontPage
                  id
                  slug
                  uri
                  seo {
                    metaRobotsNoindex
                  }
                }
              }
            }
          }
        `,
    mapping: {
      allWpPage: {
        sitemap: `pages`,
      },
      allWpPost: {
        sitemap: `posts`,
      },
      allWpProperty: {
        sitemap: `properties`,
      },
    },
    output: `/sitemap.xml`,
    exclude: [`/404`, `/404.html`, /.*\/page\/\d+/],
    createLinkInHead: true,
  },
}

module.exports = DEFAULT_OPTIONS
